/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import { Button, Typography, Box, Grid, Card, CardContent, Grid2, TextField, MenuItem } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useDispatch, useSelector } from "react-redux";
import apiService from "../apiService";
import { fetchTests } from "../store/testsSlice";

const TestRunner = ({
  selectedCampId,
  selectedDeviceId,
  details,
  onAllTestsComplete,
  userId,
  volunteerId,
}) => {

  const camp = useSelector((state) => state?.user?.userDetails?.camps);
  const tests = useSelector((state) => state?.tests?.tests?.data?.testsList);
  const [currentTestIndex, setCurrentTestIndex] = useState(null);
  const [isRunning, setIsRunning] = useState(false);
  const [testStatuses, setTestStatuses] = useState({});
  const [allTestsCompleted, setAllTestsCompleted] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState("");
  const [devices, setDevices] = useState()

  const dispatch=useDispatch()

  useEffect(() => {
    if (camp) {
      dispatch(fetchTests(camp[0].camp_id));
      fetchDevices()
    }
  }, [camp, dispatch]);

  const fetchDevices = async ()=>{
    await apiService.getDevicesForCamp(camp[0].camp_id)
    .then((res)=>{
      setDevices(res.data.devices)
    }).catch((err)=>{
    })
  }

  useEffect(() => {
    const fetchTestStatuses = async () => {
      try {
        const statuses = await Promise.all(
          tests.map(async (test) => {
            const response = await apiService.getTestStatusByUser(
              test.test_id,
              userId
            );
            return {
              testId: test.test_id,
              status: response?.data?.status || "not_started",
              deviceData: response?.data?.deviceData || null, 
            };
          })
        );

        const statusesMap = statuses.reduce((acc, { testId, status, deviceData }) => {
          acc[testId] = { status, deviceData };
          return acc;
        }, {});

        setTestStatuses(statusesMap);

        const allCompleted = statuses.every(
          ({ status }) => status === "finished"
        );
        setAllTestsCompleted(allCompleted);
      } catch (error) {
        console.error("Error fetching test statuses:", error);
      }
    };

    fetchTestStatuses();
  }, [tests, userId]);

  const startTest = useCallback(
    async (testIndex) => {
      if (
        isRunning &&
        currentTestIndex === testIndex &&
        testStatuses[tests[testIndex].test_id]?.status !== "failed"
      )
        return;
  
      setIsRunning(true);
      setCurrentTestIndex(testIndex);
      const currentTestId = tests[testIndex].test_id;
  
      const deviceName = selectedDevice;
      const storedDevices = JSON.parse(localStorage.getItem("activeDevices")) || [];
      if (!storedDevices.includes(deviceName)) {
        localStorage.setItem("activeDevices", JSON.stringify([...storedDevices, deviceName]));
      }
  
      try {
        const response = await apiService.startTestPage({
          testId: currentTestId,
          campId: selectedCampId,
          deviceId: selectedDevice,
          userId,
          volunteerId,
          status: "started",
        });
  
        if (response?.data?.success) {
          checkStatus(response.data.user_test_id, testIndex);
        } else {
          setIsRunning(false);
          setCurrentTestIndex(null);
  
          const updatedDevices = storedDevices.filter((device) => device !== deviceName);
          localStorage.setItem("activeDevices", JSON.stringify(updatedDevices));
        }
      } catch (error) {
        console.error("Error starting the test:", error);
        setIsRunning(false);
        setCurrentTestIndex(null);
        const updatedDevices = storedDevices.filter((device) => device !== deviceName);
        localStorage.setItem("activeDevices", JSON.stringify(updatedDevices));
      }
    },
    [
      isRunning,
      tests,
      selectedCampId,
      selectedDevice,
      userId,
      volunteerId,
      currentTestIndex,
      testStatuses,
    ]
  );
  
  const checkStatus = async (userTestId, testIndex) => {
    try {
      const statusResponse = await apiService.checkTestStatus(userTestId);
      const { status, deviceData } = statusResponse.data;
  
      const deviceName = selectedDevice; 
      const storedDevices = JSON.parse(localStorage.getItem("activeDevices")) || [];
  
      const updateActiveDevices = (deviceToRemove) => {
        const updatedDevices = storedDevices.filter((device) => device !== deviceToRemove);
        if (updatedDevices.length === 0) {
          localStorage.removeItem("activeDevices");
        } else {
          localStorage.setItem("activeDevices", JSON.stringify(updatedDevices));
        }
      };
  
      if (status === "failed") {
        setTestStatuses((prev) => {
          const updatedStatuses = { ...prev };
          updatedStatuses[tests[testIndex].test_id] = { status: "failed", deviceData: null };
          return updatedStatuses;
        });
  
        updateActiveDevices(deviceName);
  
        setIsRunning(false);
        setCurrentTestIndex(null);
      } else if (status === "finished") {
        setTestStatuses((prev) => {
          const updatedStatuses = { ...prev };
          updatedStatuses[tests[testIndex].test_id] = { status: "finished", deviceData };
          return updatedStatuses;
        });
  
        updateActiveDevices(deviceName);
  
        const allCompleted = tests.every(
          (test) => testStatuses[test.test_id]?.status === "finished"
        );
        setAllTestsCompleted(allCompleted);
  
        setIsRunning(false);
        setCurrentTestIndex(null);
      } else {
        setTimeout(() => {
          checkStatus(userTestId, testIndex);
        }, 1000);
      }
    } catch (error) {
      console.error("Error checking test status:", error);
  
      const storedDevices = JSON.parse(localStorage.getItem("activeDevices")) || [];
      const updatedDevices = storedDevices.filter((device) => device !== selectedDevice);
      if (updatedDevices.length === 0) {
        localStorage.removeItem("activeDevices");
      } else {
        localStorage.setItem("activeDevices", JSON.stringify(updatedDevices));
      }
  
      setIsRunning(false);
      setCurrentTestIndex(null);
    }
  };

  const handleDeviceSelect = (event) => {
    setSelectedDevice(event.target.value);
  };

  useEffect(() => {
    const handleStorageChange = () => {
      const updatedActiveDevices = JSON.parse(localStorage.getItem("activeDevices")) || [];
      setDevices((prevDevices) =>
        prevDevices?.filter(
          (device) => !updatedActiveDevices.includes(device.device_id)
        )
      );
    };
  
    window.addEventListener("storage", handleStorageChange);
  
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);
  
  
  
  

  const capitalizeFirstLetter = (string) =>
    string.charAt(0).toUpperCase() + string.slice(1);

  const formatDeviceData = (data) => {
    return (
      <Grid2 container spacing={2}>
        {Object.entries(data).map(([key, value]) => (
          <Grid2 item xs={12} sm={6} key={key}>
            <Card variant="outlined" sx={{width:125, height:100}}>
              <CardContent>
                <Typography variant="body2" color="text.secondary" sx={{ fontWeight: "semibold" }}>
                  {capitalizeFirstLetter(key.replace(/_/g, " "))}
                </Typography>
                <Typography variant="body1" sx={{fontWeight:700}}>{value}</Typography>
              </CardContent>
            </Card>
          </Grid2>
        ))}
      </Grid2>
    );
  };

  const activeDevices = JSON.parse(localStorage.getItem('activeDevices')) || [];
  const availableDevices = devices?.filter((device) => {
    console.log('Available device---', device.device_id, 'Active devices:', activeDevices);
    return !activeDevices.includes(device.device_id); 
  });

  return (
    <Box
  sx={{
    display: "flex",
    flexDirection: { xs: "column", lg: "row" },
    gap: 2,
    flexWrap: "wrap",
  }}
>
<TextField
      select
      label="Select Device"
      value={selectedDevice}
      onChange={handleDeviceSelect}
      fullWidth
      margin="normal"
      required
    >
      {availableDevices?.map((device) => (
        <MenuItem key={device.device_id} value={device.device_id}>
          {device.serial_number}
        </MenuItem>
      ))}
</TextField>
  {tests?.map((test, index) => (
    <Box
      key={test.test_id}
      sx={{
        flex: { xs: "1 1 100%", lg: "1 1 calc(33.33% - 16px)" },
        backgroundColor:
          testStatuses[test.test_id]?.status === "finished" ? "#e6ffe6" : "white",
        borderRadius: 2,
        p: 3,
        boxShadow: 3,
        mb: { xs: 2, lg: 0 },
        transition: "all 0.3s ease",
        "&:hover": {
          boxShadow: 6,
          transform: "scale(1.02)",
        },
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
        {testStatuses[test.test_id]?.status === "finished" && (
          <CheckCircleIcon sx={{ color: "green", mr: 1 }} />
        )}
        <Box sx={{ flex: 1 }}>
          <Typography variant="h6" sx={{ fontWeight: "bold", color: "#333" }}>
            {capitalizeFirstLetter(test.test_name)}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {capitalizeFirstLetter(test.test_description)}
          </Typography>
        </Box>
        <Button
          variant={
            testStatuses[test.test_id]?.status === "finished"
              ? "outlined"
              : "contained"
          }
          color="primary"
          onClick={() => startTest(index)}
          disabled={isRunning && currentTestIndex === index}
          sx={{ ml: 2 }}
        >
          {isRunning && currentTestIndex === index
            ? "Running Test..."
            : testStatuses[test.test_id]?.status === "failed"
            ? "Retest"
            : testStatuses[test.test_id]?.status === "finished"
            ? "Retest"
            : "Start Test"}
        </Button>
      </Box>

      {testStatuses[test.test_id]?.status && (
        <Box sx={{ mt: 2 }}>
          {testStatuses[test.test_id]?.status === "failed" & !isRunning ? (
            <Typography
              variant="h6"
              color="error"
              sx={{ fontWeight: "bold", textAlign: "center" }}
            >
              Wear gloves properly!!
            </Typography>
          ) : testStatuses[test.test_id]?.status === "finished" &&
            testStatuses[test.test_id]?.deviceData ? (
            <>
              <Typography variant="h6" color="primary" gutterBottom>
                Test Results:
              </Typography>
              {formatDeviceData(testStatuses[test.test_id]?.deviceData)}
            </>
          ) : null}
        </Box>
      )}
    </Box>
  ))}
  <Box mt={1} textAlign="center" sx={{ width: "100%" }}>
      <Button
        variant="contained"
        color="success"
        onClick={onAllTestsComplete}
        sx={{
          mt: 1,
          px: 5,
          py: 2,
          mb: 2,
          borderRadius: 2,
          fontSize: "1rem",
          "&:hover": {
            backgroundColor: "#4caf50",
          },
        }}
      >
        Start New Test
      </Button>
    </Box>
</Box>


  );
};

export default TestRunner;
