// src/App.js

import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Home from './pages/Home.js';
import Login from './pages/Login';
import AdminHome from './pages/Admin/AdminHome.js';
import { useSelector } from 'react-redux';

const App = () => {
  const isLoggedIn = useSelector((state) => state?.user?.isLoggedIn);

  return (
    <Router>
      <div style={{ display: "flex" }}>
        <div style={{ flex: 1, padding: "0px", marginLeft: "0px" }}>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route
              path="/admin/home"
              element={
                isLoggedIn ? (
                  <AdminHome />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/home"
              element={
                isLoggedIn ? (
                  <Home />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            {/* Redirect all other paths to login */}
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;
