import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userDetails: {},
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserDetails: (state, action) => {
      state.userDetails = action.payload;
      state.isLoggedIn = true;
    },
    logout: (state) => {
      state.userDetails = {};
      state.isLoggedIn = false;
    },
  },
});

export const { setUserDetails, logout } = userSlice.actions;

export default userSlice.reducer;
