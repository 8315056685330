import axios from 'axios';

const api = axios.create({
  baseURL: 'https://spirit.wellverse.me', // Replace with actual API base URL
  headers: {
    'Content-Type': 'application/json',
  },
});

const apiService = {
  login:(phone, password, role)=> api.post('/login',{phone, password, role}),
  getDevicesForCamp: (campId) => api.get('/getDevicesForCamp',{
    params:{campId}
  }),
  generateOtp: (phone, volunteerId) => api.post('/generate-otp', {phone, volunteerId} ),
  verifyOtp: (phone, otp, volunteerId) => api.post('/verify-otp', { phone, otp, volunteerId }),
  startTestPage: (testId, campId, deviceId, userId, volunteerId, status) => api.post('/create-user-test', testId, campId, deviceId, userId, volunteerId, status ),
  getTestsByCampId: (campId) => {
    return api.get(`/get-tests-by-campId?campId=${campId}`)
  },
  checkTestStatus:( userTestId )=>{
    return api.get(`/check-test-status?userTestId=${userTestId}`)
  },
  addUserDetails:(userDetails)=>api.post('/add-user-details', {userDetails}),
  getTestStatusByUser:(testId, userId)=>api.get(`/getTestStatusByUserId?testId=${testId}&userId=${userId}`),
  getVolunteers:()=>api.get('/getVolunteers'),
  addVolunteer:(volunteerDetails)=>api.post('/addVolunteer',volunteerDetails),
  getAllTests:()=>api.get('/getAllTests'),
  addTest:(testDetails)=>api.post('/addTest',testDetails),
  getDevices:()=>api.get('/getAllDevices'),
  addDevice:(deviceDetails)=>api.post('/addDevice', deviceDetails),
  getCamps:() => api.get('/getAllCamps'),
  addCamp:(campDetails)=>api.post('/addCamp', campDetails),
};



export default apiService;
