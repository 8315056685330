import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiService from '../apiService';

export const fetchTests = createAsyncThunk(
  'tests/fetchTests',
  async (campId, { rejectWithValue }) => {
    try {
      const tests = await apiService.getTestsByCampId(campId); 
      return tests;  
    } catch (error) {
      return rejectWithValue(error.response?.data || 'An error occurred'); 
    }
  }
);

const initialState = {
  tests:[],
  status: 'idle',
  error: null,
}
;

const testsSlice = createSlice({
  name: 'tests',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTests.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTests.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.tests = action.payload;
      })
      .addCase(fetchTests.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default testsSlice.reducer;
