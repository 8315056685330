import React, { useEffect, useState } from 'react';
import { 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
  Paper, Box, CircularProgress, Button, Tabs, Tab 
} from '@mui/material';
import apiService from '../apiService';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';

const AdminMembers = () => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [volunteers, setVolunteers] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [newVolunteer, setNewVolunteer] = useState({
    name: '',
    email: '',
    password: '',
    phone: '',
    address: ''
  });

  useEffect(() => {
    fetchMembers();
  }, []);

  const fetchMembers = () => {
    setLoading(true);
    apiService.getVolunteers()
      .then((response) => {
        setVolunteers(response?.data?.data || []);
      })
      .catch((error) => {
        console.error('Error fetching members', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleOpenAddVolunteerModal = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setNewVolunteer({
      name: '',
      email: '',
      password: '',
      phone: '',
      address:''
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewVolunteer({
      ...newVolunteer,
      [name]: value,
    });
  };

  const handleAddVolunteerSubmit = () => {
    if (!newVolunteer.name || !newVolunteer.email || !newVolunteer.password || !newVolunteer.phone) {
      alert('Please fill all fields');
      return;
    }

    apiService.addVolunteer(newVolunteer)
      .then(() => {
        fetchMembers();
        handleClose();
        alert('Volunteer added successfully!');
      })
      .catch((error) => {
        console.error('Error adding volunteer:', error);
      });
  };

  const filteredVolunteers = volunteers.filter((volunteer) => {
    console.log('volunterr sttstus---', volunteer)
    if (activeTab === 1) return volunteer.status === true;
    if (activeTab === 2) return volunteer.status === false;
    return true;
  });

  return (
    <>
      <Button variant="contained" color="primary" onClick={handleOpenAddVolunteerModal} sx={{ marginBottom: '20px' }}>
        + Add Volunteer
      </Button>

      {/* Tabs */}
      <Box sx={{ marginBottom: '20px' }}>
        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab sx={{fontWeight:700}} label="All Volunteers" />
          <Tab sx={{fontWeight:700}} label="Active Volunteers" />
          <Tab sx={{fontWeight:700}} label="Inactive Volunteers" />
        </Tabs>
      </Box>

      {/* Table */}
      <TableContainer component={Paper} style={{ maxHeight: '70vh', overflow: 'auto', maxWidth: '90vw' }}>
        <Table>
          <TableHead sx={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
            <TableRow>
              <TableCell sx={{fontWeight:700}}>ID</TableCell>
              <TableCell sx={{fontWeight:700}}>Name</TableCell>
              <TableCell sx={{fontWeight:700}}>Phone</TableCell>
              <TableCell sx={{fontWeight:700}}>Email</TableCell>
              <TableCell sx={{fontWeight:700}}>Address</TableCell>
            </TableRow>
          </TableHead>
          {loading ? (
            <Box minHeight="30vh" display="flex" justifyContent="center" alignItems="center">
              <CircularProgress />
            </Box>
          ) : (
            <TableBody>
              {filteredVolunteers.map((volunteer) => (
                <TableRow key={volunteer.volunteer_id}>
                  <TableCell>{volunteer.volunteer_id}</TableCell>
                  <TableCell>{volunteer.name}</TableCell>
                  <TableCell>{volunteer.phone}</TableCell>
                  <TableCell>{volunteer.email}</TableCell>
                  <TableCell>{volunteer.address || '---'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add New Volunteer</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            fullWidth
            margin="normal"
            value={newVolunteer.name}
            onChange={handleInputChange}
            name="name"
          />
          <TextField
            label="Email"
            fullWidth
            margin="normal"
            value={newVolunteer.email}
            onChange={handleInputChange}
            name="email"
          />
          <TextField
            label="Phone"
            fullWidth
            margin="normal"
            value={newVolunteer.phone}
            onChange={handleInputChange}
            name="phone"
          />
          <TextField
            label="Address"
            fullWidth
            margin="normal"
            value={newVolunteer.address}
            onChange={handleInputChange}
            name="address"
          />
          <TextField
            label="Password"
            fullWidth
            margin="normal"
            value={newVolunteer.password}
            onChange={handleInputChange}
            name="password"
            type="password"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddVolunteerSubmit} color="primary">
            Add Volunteer
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AdminMembers;
