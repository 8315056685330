import React, { useState } from 'react';
import { Drawer, List, ListItem, ListItemText, Box, Typography, IconButton, Button, Avatar, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import TestCollectionForm from '../components/testCollectionForm';
import { useDispatch, useSelector } from 'react-redux';
import Profile from '../components/profile';
import { logout } from '../store/userSlice';
import { persistor } from '../store/index';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedComponent, setSelectedComponent] = useState('COLLECT VITALS');
  const [anchorEl, setAnchorEl] = useState(null);

  const menuItems = [
    { label: 'COLLECT VITALS', component: <TestCollectionForm/> },
  ];

  const handleMenuClick = (label) => {
    setSelectedComponent(label);
    if (isMobile) setDrawerOpen(false); 
  };

  const handleLogout =()=>{
    dispatch(logout());
    persistor.purge();
    localStorage.removeItem("selectedCamp");
    navigate('/login')

  }

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  const handleProfileRedirect = () => {
    setSelectedComponent('PROFILE');
    setAnchorEl(null);
    if (isMobile) setDrawerOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      {isMobile && (
        <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={() => setDrawerOpen((prevOpen) => !prevOpen)}
        sx={{ position: 'fixed', top: 16, left: 16, zIndex: 1300 }}
      >
        <MenuIcon />
      </IconButton>
      
      )}

      <Drawer
        variant={isMobile ? 'temporary' : 'permanent'}
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        sx={{
          width: 240,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: 240, boxSizing: 'border-box', display: 'flex', flexDirection: 'column' },
        }}
      >
        <div style={{ width: 240, paddingTop: '64px', flexGrow: 1 }}>
          <List>
            {menuItems.map((item) => (
              <ListItem
                button
                key={item.label}
                onClick={() => handleMenuClick(item.label)}
                selected={selectedComponent === item.label}
                sx={{
                  backgroundColor:
                    selectedComponent === item.label ? 'primary.main' : 'transparent',
                  color: selectedComponent === item.label ? 'white' : 'inherit',
                  cursor: 'pointer',
                  '&:hover': { backgroundColor: 'primary.light', color: 'white', fontWeight: 700 },
                }}
              >
                <ListItemText
                  primary={item.label}
                  primaryTypographyProps={{
                    fontSize: { xs: 16, md: 18 },
                    fontWeight: 600,
                    marginLeft: { xs: 2, md: 2 },
                  }}
                />
              </ListItem>
            ))}
          </List>
        </div>
        <div
          style={{
            position: 'absolute',
            bottom: 10,
            left: 10,
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <IconButton onClick={handleProfileMenuOpen}>
          <Avatar sx={{ bgcolor: 'primary.main', border:'solid' }} src="/person.jpg"></Avatar>

          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleProfileMenuClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
          <MenuItem onClick={handleProfileRedirect}>View Profile</MenuItem>
          </Menu>
          <Button sx={{ marginTop: 'auto', marginBottom:'10px', color:'red', fontWeight:700, fontSize:16 }} 
        onClick={handleLogout}> 
        LOG OUT
        </Button>
        </div>
      </Drawer>

      <div
        style={{
          flexGrow: 1,
          padding: theme.spacing(3),
          marginLeft: isMobile && drawerOpen ? '240px' : 0,
          paddingTop: isMobile ? '60px' : '30px',
          height: '90vh',
          overflow: 'auto',
        }}
      >
        <Typography
          gutterBottom
          sx={{
            fontWeight: 700,
            fontSize: { xs: 26, md: 32 },
            textAlign: 'center',
            justifyContent: { xs: 'start', md: 'center' },
          }}
        >
          {selectedComponent === 'PROFILE' ? 'Profile Details' : selectedComponent}
        </Typography>

        <Box>
          {selectedComponent === 'PROFILE' ? (
            <Profile />
          ) : (
            menuItems.find((item) => item.label === selectedComponent)?.component
          )}
        </Box>
      </div>
    </Box>
  );
};

export default Home;
