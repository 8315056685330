import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Tabs,
  Tab,
} from '@mui/material';
import apiService from '../apiService';

const TestDetails = () => {
  const [loading, setLoading] = useState(false);
  const [testDetails, setTestDetails] = useState([]);
  const [filteredTests, setFilteredTests] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [open, setOpen] = useState(false);
  const [newTest, setNewTest] = useState({
    test_name: '',
    test_description: '',
    unit: '',
    normal_range: '',
  });

  useEffect(() => {
    fetchAllTests();
  }, []);

  useEffect(() => {
    filterTestsByStatus();
  }, [activeTab, testDetails]);

  const fetchAllTests = async () => {
    setLoading(true);
    try {
      const res = await apiService.getAllTests();
      setTestDetails(res?.data?.data);
    } catch (err) {
      alert('Error fetching tests');
    } finally {
      setLoading(false);
    }
  };

  const filterTestsByStatus = () => {
    if (activeTab === 0) {
      setFilteredTests(testDetails);
    } else if (activeTab === 1) {
      setFilteredTests(testDetails.filter((test) => test.status === true));
    } else if (activeTab === 2) {
      setFilteredTests(testDetails.filter((test) => test.status === false));
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleOpenAddTestModal = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setNewTest({
      test_name: '',
      test_description: '',
      unit: '',
      normal_range: '',
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewTest({
      ...newTest,
      [name]: value,
    });
  };

  const handleAddTestSubmit = async () => {
    if (!newTest.test_name || !newTest.unit || !newTest.normal_range || !newTest.test_description) {
      alert('Please fill all fields');
      return;
    }

    try {
      await apiService.addTest(newTest);
      fetchAllTests();
      handleClose();
    } catch (error) {
      console.error('Error adding test:', error);
      alert('Error adding new test');
    }
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpenAddTestModal}
        sx={{ marginBottom: '20px' }}
      >
        + Add Test
      </Button>

      <Box sx={{ marginBottom: '20px' }}>
        <Tabs value={activeTab} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
          <Tab sx={{fontWeight:700}} label="All" />
          <Tab sx={{fontWeight:700}} label="Active" />
          <Tab sx={{fontWeight:700}} label="Inactive" />
        </Tabs>
      </Box>

      <TableContainer component={Paper} style={{ maxHeight: '70vh', overflow: 'auto', maxWidth: '90vw' }}>
        <Table>
          <TableHead sx={{ position: 'sticky', top: 0, backgroundColor: 'white' }}>
            <TableRow>
              <TableCell sx={{fontWeight:700}}>ID</TableCell>
              <TableCell sx={{fontWeight:700}}>Test Name</TableCell>
              <TableCell sx={{fontWeight:700}}>Description</TableCell>
            </TableRow>
          </TableHead>
          {loading ? (
            <Box minHeight="30vh" display="flex" justifyContent="center" alignItems="center">
              <CircularProgress />
            </Box>
          ) : (
            <TableBody>
              {filteredTests?.map((test) => (
                <TableRow key={test.test_id}>
                  <TableCell>{test.test_id}</TableCell>
                  <TableCell>{test.test_name}</TableCell>
                  <TableCell>{test.test_description}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add New Test</DialogTitle>
        <DialogContent>
          <TextField
            label="Test Name"
            fullWidth
            margin="normal"
            value={newTest.test_name}
            onChange={handleInputChange}
            name="test_name"
          />
          <TextField
            label="Description"
            fullWidth
            margin="normal"
            value={newTest.test_description}
            onChange={handleInputChange}
            name="test_description"
          />
          <TextField
            label="Unit"
            fullWidth
            margin="normal"
            value={newTest.unit}
            onChange={handleInputChange}
            name="unit"
          />
          <TextField
            label="Normal Range"
            fullWidth
            margin="normal"
            value={newTest.normal_range}
            onChange={handleInputChange}
            name="normal_range"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddTestSubmit} color="primary">
            Add Test
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TestDetails;
