import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  CircularProgress,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Tabs,
  Tab,
} from '@mui/material';
import apiService from '../apiService';

const CampDetails = () => {
  const [loading, setLoading] = useState(false);
  const [camps, setCamps] = useState([]);
  const [open, setOpen] = useState(false);
  const [newCamp, setNewCamp] = useState({
    name: '',
    location: '',
    start_date: '',
    end_date: '',
    organizer: '',
  });
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    fetchCamps();
  }, []);

  const fetchCamps = () => {
    setLoading(true);
    apiService
      .getCamps()
      .then((response) => {
        setCamps(response.data.data);
      })
      .catch((error) => {
        console.error('Error fetching camps', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleAddCamp = () => {
    setLoading(true);
    apiService
      .addCamp(newCamp)
      .then(() => {
        setOpen(false);
        alert('Camp added successfully!');
        fetchCamps();
      })
      .catch((error) => {
        console.error('Error adding camp', error);
        alert('Error adding camp!');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewCamp({ ...newCamp, [name]: value });
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const filteredCamps = camps.filter((camp) => {
    const startDate = new Date(camp.start_date);
    const todayStartOfDay = new Date();
    todayStartOfDay.setHours(0, 0, 0, 0); 
    const todayEndOfDay = new Date();
    todayEndOfDay.setHours(23, 59, 59, 999); 
    
    if (tabIndex === 1) {
      return startDate > todayEndOfDay;
    } else if(tabIndex === 0) {
      return startDate >= todayStartOfDay && startDate <= todayEndOfDay;
    } else {
      return startDate < todayStartOfDay;
    }
  });
  

  return (
    <>
      <Box display="flex" mb={2}>
        <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
          + Add Camp
        </Button>
      </Box>

      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        sx={{ marginBottom: '20px' }}
      >
        <Tab sx={{fontWeight:700}} label="Today" />
        <Tab sx={{fontWeight:700}} label="Upcoming" />
        <Tab sx={{fontWeight:700}} label="Finished" />
      </Tabs>

      <TableContainer component={Paper} style={{ maxHeight: '70vh', overflow: 'auto', maxWidth: '90vw' }}>
        <Table>
          <TableHead sx={{ position: 'sticky', top: 0, backgroundColor: 'white' }}>
            <TableRow>
              <TableCell sx={{fontWeight:700}}>ID</TableCell>
              <TableCell sx={{fontWeight:700}}>Name</TableCell>
              <TableCell sx={{fontWeight:700}}>Location</TableCell>
              <TableCell sx={{fontWeight:700}}>Start Date</TableCell>
              <TableCell sx={{fontWeight:700}}>End Date</TableCell>
              <TableCell sx={{fontWeight:700}}>Organizer</TableCell>
            </TableRow>
          </TableHead>
          {loading ? (
            <Box minHeight="30vh" display="flex" justifyContent="center" alignItems="center">
              <CircularProgress />
            </Box>
          ) : (
            <TableBody>
              {filteredCamps?.map((camp) => (
                <TableRow key={camp.camp_id}>
                  <TableCell>{camp.camp_id}</TableCell>
                  <TableCell>{camp.name}</TableCell>
                  <TableCell>{camp.location}</TableCell>
                  <TableCell>
                    {new Date(camp.start_date).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                    })}
                  </TableCell>
                  <TableCell>
                    {new Date(camp.end_date).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                    })}
                  </TableCell>
                  <TableCell>{camp.organizer}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Add New Camp</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Name"
            name="name"
            value={newCamp.name}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Location"
            name="location"
            value={newCamp.location}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Start Date"
            name="start_date"
            type="date"
            value={newCamp.start_date}
            onChange={handleInputChange}
            InputLabelProps={{ shrink: true }}
            fullWidth
          />
          <TextField
            margin="dense"
            label="End Date"
            name="end_date"
            type="date"
            value={newCamp.end_date}
            onChange={handleInputChange}
            InputLabelProps={{ shrink: true }}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Organizer"
            name="organizer"
            value={newCamp.organizer}
            onChange={handleInputChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button onClick={handleAddCamp} color="primary" variant="contained">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CampDetails;
