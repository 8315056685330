/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { TextField, Button, MenuItem, Box, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircularProgress from '@mui/material/CircularProgress'; 
import { useDispatch, useSelector } from 'react-redux';
import { fetchTests } from '../store/testsSlice';
import TestRunner from './testRunner';
import apiService from '../apiService';

const TestCollectionForm = () => {
  const dispatch = useDispatch();
  const volunteerId = useSelector((state) => state?.user?.userDetails?.user?.volunteer_id)
  const camp = useSelector((state) => state?.user?.userDetails?.camps);

  const [details, setDetails] = useState({ name: "", phone: "", address: "" });
  const [otpSent, setOtpSent] = useState(false);
  const [verified, setVerified] = useState(false);
  const [showOtpDialog, setShowOtpDialog] = useState(false);
  const [inputOtp, setInputOtp] = useState("");
  const [showProgress, setShowProgress] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState("");
  const [userId, setUserId] = useState();
  const [loading, setLoading] = useState(false);
  const [devices, setDevices] = useState()
  const [loadingForOtp, setLoadingForOtp] = useState(false)
  const [verifyLoading, setVerifyLoading] = useState(false)

  const isFormComplete = details.name && details.phone && details.address && camp && verified;

  useEffect(() => {
    if (camp) {
      dispatch(fetchTests(camp[0].camp_id));
      fetchDevices()
    }
  }, [camp, dispatch]);

  const fetchDevices = async ()=>{
    await apiService.getDevicesForCamp(camp[0].camp_id)
    .then((res)=>{
      setDevices(res.data.devices)
    }).catch((err)=>{
    })
  }

  const handleDeviceSelect = (event) => {
    setSelectedDevice(event.target.value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
  };

  const generateOtp = async () => {
    setLoadingForOtp(true)
    await apiService.generateOtp(details.phone, volunteerId)
    .then((res)=>{
      setLoadingForOtp(false)
      setOtpSent(true);
      setShowOtpDialog(true);
    })
    .catch((err)=>{
      if(err.status === 500){
      alert(err?.response?.data?.message)
      }
      setLoadingForOtp(false)
    })
  };

  const handleOtpVerification =async ()=>{
    setVerifyLoading(true)
    await apiService.verifyOtp(details.phone, inputOtp, volunteerId )
    .then((res)=>{
      setVerifyLoading(false)
      if(res.status === 200){
        setVerified(true);
        setShowOtpDialog(false);
      }
    })
    .catch((err)=>{
      if(err.status === 400){
      alert("Incorrect OTP, please try again.");
      } else if(err.status === 500) {
        alert('Something went wrong')
      }
      setVerifyLoading(false)
    })
  }

    const handleStartTest = async () => {
    setLoading(true);
      
    await apiService.addUserDetails(details)
    .then((res)=>{
      setUserId(res.data.user.user_id);
      setLoading(false); 
      setShowProgress(true);
    }).catch((err)=>{
      setLoading(false);  
      alert('Error starting test');
    })

    
  }

  const resetForm = () => {
    setDetails({ name: "", phone: "", address: "" });
    setOtpSent(false);
    setVerified(false);
    setShowProgress(false);
    setInputOtp("");
    setSelectedDevice("");
  };

  const activeDevices = JSON.parse(localStorage.getItem('activeDevices')) || [];
  const availableDevices = devices?.filter((device) => {
    console.log('Available device---', device.device_id, 'Active devices:', activeDevices);
    return !activeDevices.includes(device.device_id); 
  });

  console.log('availableDevices', availableDevices)

  return (
    <Box sx={{ maxWidth: 400, mx: 'auto', mt: 2 }}>
      {!showProgress && camp && (
        <Typography variant="body1" sx={{ padding: '8px 0', fontSize:{xs:18, md:20} }}>
          <b>Camp Name:</b> {camp[0].name}
        </Typography>
      )}

      {camp && !showProgress && (
        <>
          {/* <TextField
            select
            label="Select Device"
            value={selectedDevice}
            onChange={handleDeviceSelect}
            fullWidth
            margin="normal"
            required
          >
            {availableDevices?.map((device) => (
              <MenuItem key={device.device_id} value={device.device_id}>
                {device.serial_number}
              </MenuItem>
            ))}
          </TextField> */}
          <TextField
            label="Name"
            name="name"
            value={details.name}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Address"
            name="address"
            value={details.address}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
          />

          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 0 }}>
            <TextField
              label="Phone"
              name="phone"
              value={details.phone}
              onChange={handleChange}
              fullWidth
              margin="normal"
              disabled={verified}
              required
            />
            <Button
              variant="contained"
              onClick={generateOtp}
              disabled={!details.phone || otpSent || verified}
              sx={{
                height: 60,
                width: 150,
                borderRadius: '10px',
                backgroundColor: verified ? '#3DD598' : 'primary.main',
                color: 'white',
                '&:hover': {
                  backgroundColor: verified ? '#2BAF78' : 'primary.dark',
                },
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 1,
                marginTop: '10px',
              }}
            >
              {verified ? (
                <>
                  <span>Verified</span>
                  <CheckCircleIcon sx={{ color: 'green', fontSize: 20 }} />
                </>
              ) :
              loadingForOtp
              ?
              'Please Wait...'
              :
               otpSent ? (
                "OTP Sent"
              ) : (
                "Send OTP"
              )}
            </Button>
          </Box>

          <Button
            variant="contained"
            color="primary"
            disabled={!isFormComplete || showProgress}
            fullWidth
            sx={{ mt: 2, height: 50, borderRadius: '10px' }}
            onClick={handleStartTest}
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : 'Start Test'}
          </Button>
        </>
      )}

      {showProgress && (
        <TestRunner
          selectedCampId={camp[0].camp_id}
          selectedDeviceId={selectedDevice}
          details={details}
          onAllTestsComplete={resetForm}
          userId={userId} 
          volunteerId={volunteerId}
        />
      )}

      <Dialog open={showOtpDialog} onClose={() => setShowOtpDialog(false)}>
        <DialogTitle>Enter OTP</DialogTitle>
        <DialogContent>
          <TextField
            label="OTP"
            value={inputOtp}
            onChange={(e) => setInputOtp(e.target.value)}
            fullWidth
            margin="dense"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOtpVerification} color="primary">
            {verifyLoading ? 'Please Wait' :  'Verify'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TestCollectionForm;
