import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import apiService from '../apiService';

const DeviceDetails = () => {
  const [loading, setLoading] = useState(false);
  const [devices, setDevices] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [newDevice, setNewDevice] = useState({
    deviceType: '',
    serialNumber: '',
    mac_id:''
  });

  useEffect(() => {
    fetchDevices();
  }, []);

  const fetchDevices = () => {
    setLoading(true);
    apiService
      .getDevices()
      .then((response) => {
        setDevices(response.data.data);
      })
      .catch((error) => {
        console.error('Error fetching devices', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleAddDevice = () => {
    apiService
      .addDevice(newDevice)
      .then(() => {
        fetchDevices(); 
        setOpenDialog(false); 
        setNewDevice({ deviceType: '', serialNumber: '', mac_id:'' });
      })
      .catch((error) => {
        console.error('Error adding device', error);
        alert('Failed to add device');
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewDevice((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <>
      <Box display="flex" mb={2} pr={2}>
        <Button variant="contained" color="primary" onClick={() => setOpenDialog(true)}>
          + Add Device
        </Button>
      </Box>

      <TableContainer component={Paper} style={{ maxHeight: '70vh', overflow: 'auto', maxWidth: '90vw' }}>
        <Table>
          <TableHead sx={{ position: 'sticky', top: 0, backgroundColor: 'white' }}>
            <TableRow>
              <TableCell sx={{fontWeight:700}}>ID</TableCell>
              {/* <TableCell>Device Name</TableCell> */}
              <TableCell sx={{fontWeight:700}}>Device Type</TableCell>
              <TableCell sx={{fontWeight:700}}>Serial Number</TableCell>
              {/* <TableCell>Description</TableCell> */}
              <TableCell sx={{fontWeight:700}}>Mac ID</TableCell>
              <TableCell sx={{fontWeight:700}}>Status</TableCell>
            </TableRow>
          </TableHead>
          {loading ? (
            <Box minHeight="30vh" display="flex" justifyContent="center" alignItems="center">
              <CircularProgress />
            </Box>
          ) : (
            <TableBody>
              {devices?.map((device) => (
                <TableRow key={device.id}>
                  <TableCell>{device.device_id}</TableCell>
                  <TableCell>{device.device_type}</TableCell>
                  <TableCell>{device.serial_number}</TableCell>
                  <TableCell>{device.mac_id}</TableCell>
                  <TableCell>{device.status}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Add New Device</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Device Type"
            name="deviceType"
            value={newDevice.deviceType}
            onChange={handleChange}
            fullWidth
            required
          />
          <TextField
            margin="dense"
            label="Serial Number"
            name="serialNumber"
            value={newDevice.serialNumber}
            onChange={handleChange}
            fullWidth
            required
          />
          <TextField
            margin="dense"
            label="Mac ID"
            name="mac_id"
            value={newDevice.mac_id}
            onChange={handleChange}
            fullWidth
            required
          />
          
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleAddDevice} color="primary" variant="contained">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeviceDetails;
