import React, { useState } from "react";
import { TextField, Button, Container, Typography, Box, MenuItem } from "@mui/material";
import { useDispatch } from "react-redux";
import { setUserDetails } from "../store/userSlice";
import { useNavigate } from "react-router-dom";
import apiService from "../apiService";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [phone, setPhone] = useState();
  const [password, setPassword] = useState("");
  const [role, setRole] = useState(""); 
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    await apiService
      .login(phone, password, role)
      .then((res) => {
        if (res.data.success) {
          dispatch(
            role === 'volunteer' ? setUserDetails({ user: res.data.volunteer, camps: res.data.camps }) : setUserDetails({ user: res.data.admin, camps: null })
          );
        navigate( role === 'admin' ? '/admin/home' : '/home');
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error logging in", err);
        alert(err.response.data.message);
        setLoading(false);
      });
  };

  const styles = {
    container: {
      maxWidth: "350px",
      margin: "auto",
      padding: "20px",
      border: "1px solid #ccc",
      borderRadius: "8px",
      boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
      marginTop: "80px",
      textAlign: "center",
    },
    button: {
      marginTop: "10px",
      backgroundColor: "#007bff",
      color: "white",
      "&:hover": {
        backgroundColor: "#0056b3",
      },
    },
    heading: {
      marginBottom: "20px",
    },
    formField: {
      width: "100%",
      margin: "10px 0",
    },
  };

  return (
    <>
      <Typography
        variant="h3"
        align="center"
        sx={{
          marginTop: { xs: "40px", sm: "40px", md: "40px" },
          fontSize: { xs: "2rem", sm: "3rem", md: "3rem" },
          fontWeight:700,
        }}
      >
        Wellverse Health Tracker
      </Typography>
      <Container style={styles.container}>
        <Typography variant="h5" style={styles.heading}>
          Login
        </Typography>
        <form onSubmit={handleSubmit}>
          <Box component="div" style={styles.formField}>
            <TextField
              label="Phone"
              variant="outlined"
              type="phone"
              fullWidth
              margin="normal"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
          <Box component="div" style={styles.formField}>
            <TextField
              label="Password"
              variant="outlined"
              type="password"
              fullWidth
              margin="normal"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
          <Box component="div" style={styles.formField}>
          <TextField
            select
            label="Select Role"
            variant="outlined"
            fullWidth
            margin="normal"
            value={role}
            onChange={(e) => setRole(e.target.value)}
            required
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  style: {
                    textAlign: 'start',
                  },
                },
              },
            }}
          >
            <MenuItem value="admin">Admin</MenuItem>
            <MenuItem value="volunteer">Volunteer</MenuItem>
          </TextField>
        </Box>
          <Box component="div" style={styles.formField}>
            <Button
              type="submit"
              variant="contained"
              fullWidth
              style={styles.button}
            >
              {loading ? "Logging In" : "Login"}
            </Button>
          </Box>
        </form>
      </Container>
    </>
  );
};

export default Login;
