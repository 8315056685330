import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography, Paper, Stack } from '@mui/material';

const Profile = () => {
  const user = useSelector((state) => state?.user?.userDetails?.user);

  return (
    <Paper elevation={3} sx={{ padding: 2, maxWidth: 400, margin: '0 auto' }}>
      <Stack spacing={2}>
        <Box display="flex" >
          <Typography variant="body1" color="textSecondary" sx={{fontWeight:600}}>ID:</Typography>
          <Typography variant="body1" sx={{marginLeft:'20px'}}>{user?.volunteer_id || user?.id || 'N/A'}</Typography>
        </Box>

        <Box display="flex" >
          <Typography variant="body1" color="textSecondary" sx={{fontWeight:600}}>Name:</Typography>
          <Typography variant="body1" sx={{marginLeft:'20px'}}>{user?.name || 'N/A'}</Typography>
        </Box>

        <Box display="flex" >
          <Typography variant="body1" color="textSecondary" sx={{fontWeight:600}}>Email:</Typography>
          <Typography variant="body1" sx={{marginLeft:'20px'}}>{user?.email || 'N/A'}</Typography>
        </Box>

        <Box display="flex" >
          <Typography variant="body1" color="textSecondary" sx={{fontWeight:600}}>Phone:</Typography>
          <Typography variant="body1" sx={{marginLeft:'20px'}}>{user?.phone || 'N/A'}</Typography>
        </Box>
      </Stack>
    </Paper>
  );
};

export default Profile;
